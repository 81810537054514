<template>
  <v-card
    v-bind="$attrs"
    :height="reveal ? 350 : 120"
    v-if="item"
    class="
      d-flex
      overflow-x-hidden overflow-y-auto
      each-alarm
      rounded-10
      align-center
      pa-3
      justify-space-between
    "
    outlined
  >
    <div class="d-flex align-center justify-start">
      <v-icon :size="80" :color="active ? 'primary' : 'secondary'">
        {{ active ? 'mdi-alarm' : 'mdi-alarm-off' }}
      </v-icon>
      <div class="d-flex flex-column align-start ml-2 justify-start">
        <span class="my-1 primary--text fw-700">
          {{ item.title | ucwords }}
        </span>
        <strong class="my-1 fs-18 fw-900 primary--text">
          {{ formatted_time }}
        </strong>
        <div>
          <span class="fs-12 fw-700 primary--text"> {{ item.timezone }} </span>
          <span v-if="item.repeat != 'none'" class="fs-12 fw-700 primary--text">
            | {{ item.repeat | ucwords }}
          </span>
        </div>
      </div>
    </div>
    <div class="d-flex flex-nowrap align-center justify-end">
      <IconMenu
        @delete="$emit('delete', item)"
        @edit="$emit('edit', item)"
        @view="reveal = true"
      ></IconMenu>
      <v-switch height="20" @change="toggleStatus" v-model="active"></v-switch>
    </div>
    <v-expand-transition>
      <v-card
        flat
        v-if="reveal"
        class="transition-fast-in-fast-out v-card--reveal"
        style="height: 100%"
      >
        <v-card-text class="pb-0">
          <Editor :editable="false" :value="item.description"></Editor>
        </v-card-text>
        <v-card-actions class="pt-0 d-flex align-center justify-center">
          <v-btn
            depressed
            small
            class="text-none"
            color="primary accent-4"
            @click="reveal = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expand-transition>
  </v-card>
</template>
<script>
import _ from 'lodash'
import moment from 'moment'
import request from '@/services/axios_instance'
import Editor from '@/common/Editor/Editor.vue'
import IconMenu from '@/common/Menus/IconMenu.vue'
export default {
  components: { Editor, IconMenu },
  name: 'EachAlarm',
  props: {
    value: { type: Object }
  },
  watch: {
    value: {
      handler: function (val) {
        this.item = _.cloneDeep(val)
        this.active = val.active
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    formatted_time() {
      return this.item
        ? moment(this.item.alarm_on).format('LLL')
        : 'Jan 01 2022 00:00 AM'
    }
  },
  data: () => ({
    item: null,
    active: 0,
    reveal: false
  }),
  methods: {
    toggleStatus() {
      request
        .put(`api/alarms/${this.item.id}/toggle-status`, {})
        .then(({ data }) => {
          this.$emit('input', data)
          this.appSnackbar('Alarm status updated')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.each-alarm {
  .v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
  }
}
</style>
