<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            icon
            color="primary"
            dark
            :disabled="disabled"
            v-bind="{ ...attrs, ...$attrs }"
            v-on="{ ...tooltip, ...menu }"
          >
            <v-icon :class="iconClass" :color="iconColor">{{ icon }}</v-icon>
          </v-btn>
        </template>
        <span>{{ tip }}</span>
      </v-tooltip>
    </template>
    <slot name="default">
      <v-list dense>
        <slot name="prepend"></slot>
        <v-list-item v-if="hasView" @click="$emit('view')">
          <v-list-item-icon class="mr-2">
            <v-icon color="primary">mdi-eye-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="primary--text">View</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="hasEdit" @click="$emit('edit')">
          <v-list-item-icon class="mr-2">
            <v-icon color="primary">mdi-circle-edit-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="primary--text">Edit</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="hasDelete" @click="$emit('delete')">
          <v-list-item-icon class="mr-2">
            <v-icon color="error">mdi-delete-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="primary--text">Delete</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <slot name="append"></slot>
      </v-list>
    </slot>
  </v-menu>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'IconMenu',
  props: {
    iconClass: { type: String, default: '' },
    iconColor: { type: String, default: 'primary' },
    disabled: { type: Boolean, default: false },
    icon: { type: String, default: 'mdi-dots-vertical-circle-outline' },
    tip: { type: String, default: 'Actions' },
    hasView: { type: Boolean, default: true },
    hasEdit: { type: Boolean, default: true },
    hasDelete: { type: Boolean, default: true }
  }
}
</script>

<style scoped></style>
