<template>
  <ExpandedCard title="Alarms" :loading="false">
    <template v-slot:default>
      <Alarm></Alarm>
    </template>
  </ExpandedCard>
</template>

<script>
import Alarm from '@/modules/Alarm/Alarm.vue'
import ExpandedCard from '../components/ExpandedCard.vue'
export default {
  components: { ExpandedCard, Alarm }
}
</script>

<style lang="scss" scoped></style>
