<template>
  <CustomDialog
    v-model="dialog"
    @button1="close"
    @button2="save"
    :title="title"
    max-width="800"
    button1-text="Cancel"
    :button2-text="isEdit ? 'Update' : 'Save'"
    :main-btn-disabled="!validForm"
  >
    <template v-slot:content>
      <v-form ref="form" v-model="valid">
        <label class="subtitle-2 primary--text">Alarm Title</label>
        <v-text-field
          hide-details="auto"
          dense
          v-model="mapping.title"
          outlined
          :rules="[requiredRule()]"
        ></v-text-field>
        <v-row dense>
          <v-col cols="12" md="12" sm="12">
            <label class="subtitle-2 primary--text">Alarm On</label>
            <KirbyDateTimePicker
              type="datetime"
              :rules="[requiredRule()]"
              :min-date="today"
              v-model="mapping.timestamp"
            ></KirbyDateTimePicker>
          </v-col>

          <v-col cols="12" md="6" sm="12">
            <label class="subtitle-2 primary--text">Timezone</label>
            <Timezone
              hide-details="auto"
              dense
              v-model="mapping.timezone"
              outlined
              :rules="[requiredRule()]"
            ></Timezone>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <label class="subtitle-2 primary--text">Repeat Alarm</label>
            <v-select
              outlined
              dense
              :rules="[requiredRule()]"
              :disabled="!mapping.timestamp"
              item-text="text"
              item-value="value"
              hide-details="auto"
              v-model="mapping.repeat"
              :items="repeat_every_list"
            ></v-select>
          </v-col>
        </v-row>
        <label class="subtitle-2 primary--text">Alarm Description</label>
        <Editor min-height="120" v-model="mapping.description"></Editor>
      </v-form>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import Editor from '@/common/Editor/Editor.vue'
import Timezone from '@/common/Forms/Timezone.vue'
import moment from 'moment-timezone'
import KirbyDateTimePicker from '@/common/Pickers/KirbyDateTimePicker.vue'
export default {
  name: 'AlarmDialog',
  components: { CustomDialog, Editor, Timezone, KirbyDateTimePicker },
  props: {
    value: { type: Boolean, default: false },
    item: { type: Object }
  },
  data: () => ({
    dialog: false,
    valid: false,
    mapping: {
      title: null,
      description: null,
      timezone: 'UTC',
      repeat: 'none',
      timestamp: null
    }
  }),
  computed: {
    validForm() {
      return this.mapping.title &&
        this.mapping.timestamp &&
        this.mapping.timezone
        ? true
        : false
    },
    today() {
      return moment().tz(this.myTz()).format('YYYY-MM-DD HH:mm:ss')
    },
    repeat_every_list() {
      return [
        { text: "Doesn't Repeat", value: 'none' },
        { text: 'Daily at same time', value: 'daily' },
        { text: 'Weekly on same day and time', value: 'weekly' },
        { text: 'Monthly on same date and time', value: 'monthly' }
        // { text: 'Custom', value: 'custom' }
      ]
    },
    isEdit() {
      return this.item ? true : false
    },
    title() {
      return this.isEdit
        ? `Update Alarm : ${this.item.title}`
        : 'Create New Alarm'
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.dialog = val
        if (val) {
          if (!this.isEdit) {
            this.mapping.title = null
            this.mapping.description = null
            this.mapping.repeat = 'none'
          }
          this.resetValidation()
        }
      },
      immediate: true
    },
    dialog: {
      handler: function (val) {
        this.$emit('input', val)
      },
      immediate: true
    },
    item: {
      handler: function (val) {
        if (val) {
          this.mapping = {
            title: val.title,
            description: val.description,
            repeat: val.repeat,
            timezone: val.timezone,
            timestamp: val.alarm_on
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    save() {
      this.$emit(this.isEdit ? 'update' : 'save', this.mapping)
    },
    close() {
      this.dialog = false
    }
  }
}
</script>

<style lang="scss"></style>
