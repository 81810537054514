<template>
  <CustomDialog
    v-model="dialog"
    @button1="close"
    @button2="save"
    title="Alarm Settings"
    :has-button2="false"
    max-width="800"
    button1-text="Close"
  >
    <template v-slot:content>
      <v-card flat>
        <v-card-text>
          <v-row dense align="center" justify="space-between">
            <strong class="subtitle-2 primary--text">
              Select Alarm Behavior
            </strong>
            <v-radio-group
              v-model="repeatAlarm"
              row
              mandatory
              dense
              hide-details="auto"
              class="my-0 radio-primary--text"
            >
              <v-radio value="false" label="Alarm Once"></v-radio>
              <v-radio value="true" label="Alarm Continuously"></v-radio>
            </v-radio-group>
          </v-row>
          <v-row dense align="center" justify="space-between">
            <strong class="subtitle-2 primary--text">
              Select Alarm Ringtone
            </strong>
            <v-btn
              color="primary"
              @click="askForPushNotification"
              class="text-none"
              v-if="!isNotificationGranted"
            >
              Allow push notification
            </v-btn>
            <label class="d-flex align-center justify-start mr-5">
              <v-checkbox
                on-icon="mdi-radiobox-marked"
                off-icon="mdi-radiobox-blank"
                v-model="defaultSound"
                value="none"
              >
              </v-checkbox>
              <span class="fw-500 cursor-pointer fs-12 primary--text">
                Mute Alarm
              </span>
            </label>
          </v-row>
          <v-sheet
            v-for="(sound, index) in soundList"
            :key="sound.name"
            outlined
            height="50"
            class="
              rounded-10
              mb-1
              d-flex
              px-2
              align-center
              justify-space-between
            "
          >
            <div>
              <v-btn icon class="mr-2" @click="togglePlay(sound, index)">
                <v-icon color="primary" size="30">
                  {{ !isPlaying(sound) ? 'mdi-play' : 'mdi-pause' }}
                </v-icon>
              </v-btn>
              <strong class="primary--text">{{ sound.name | ucwords }}</strong>
            </div>
            <div>
              <v-checkbox
                on-icon="mdi-radiobox-marked"
                off-icon="mdi-radiobox-blank"
                v-model="defaultSound"
                :value="sound.name"
              >
                <template v-slot:label>
                  <span class="fw-500 fs-12 primary--text"> Set As Alarm </span>
                </template>
              </v-checkbox>
            </div>
          </v-sheet>
          <v-alert type="info" v-if="isFireFox">
            <div class="d-flex align-center justify-space-between">
              <span>
                For Mozilla Firefox users, You need to allow AutoPlay on this
                site for alarm ringtone to work.
              </span>
              <v-menu v-model="menu" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-img
                    v-bind="attrs"
                    v-on="on"
                    class="cursor-pointer"
                    height="70"
                    width="100"
                    contain
                    :src="require('@/assets/tutorials/autoplay-permission.png')"
                  ></v-img>
                </template>
                <v-card>
                  <v-img
                    contain
                    :src="require('@/assets/tutorials/autoplay-permission.png')"
                  ></v-img>
                </v-card>
              </v-menu>
            </div>
          </v-alert>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import Editor from '@/common/Editor/Editor.vue'
import Timezone from '@/common/Forms/Timezone.vue'
import moment from 'moment'
import _ from 'lodash'
import IconMenu from '@/common/Menus/IconMenu.vue'
import playAlert from 'alert-sound-notify'
export default {
  components: { CustomDialog, Editor, Timezone, IconMenu },
  name: 'AlarmSettingDialog',
  props: {
    value: { type: Boolean, default: false },
    item: { type: Object }
  },
  data: () => ({
    dialog: false,
    currentPlaying: null,
    isNotificationGranted: false,
    selectedAudio: localStorage.getItem('alarm-audio'),
    repeat_alarm: localStorage.getItem('alarm-repeat') || 'false',
    valid: false,
    soundList: [
      {
        name: 'door_bell',
        play: false,
        audio: require('@/assets/sounds/door-bell.mp3')
      },
      {
        name: 'orchestral_emergency_alarm',
        play: false,
        audio: require('@/assets/sounds/orchestral-emergency-alarm.mp3')
      },
      {
        name: 'phone_ring',
        play: false,
        audio: require('@/assets/sounds/phone-ring.mp3')
      },
      {
        name: 'phone_ring_2',
        play: false,
        audio: require('@/assets/sounds/phone-ring-2.mp3')
      },
      {
        name: 'sci_fi',
        play: false,
        audio: require('@/assets/sounds/sci-fi.mp3')
      },
      {
        name: 'urgent_simple_tone',
        play: false,
        audio: require('@/assets/sounds/urgent-simple-tone.mp3')
      },
      {
        name: 'wrong_answer',
        play: false,
        audio: require('@/assets/sounds/wrong-answer.mp3')
      }
    ]
  }),
  created() {
    this.soundList.forEach((item) => {
      playAlert.content[item.name] = [item.audio]
    })
  },
  computed: {
    isFireFox() {
      return navigator.userAgent.indexOf('Firefox') > 0
    },
    today() {
      return moment().subtract(4, 'hours').format('YYYY-MM-DD HH:mm:00')
    },
    repeat_every_list() {
      return [
        { text: "Doesn't Repeat", value: 'none' },
        { text: 'Daily at same time', value: 'daily' },
        { text: 'Weekly on same day and time', value: 'weekly' },
        { text: 'Monthly on same date and time', value: 'monthly' }
        // { text: 'Custom', value: 'custom' }
      ]
    },
    repeatAlarm: {
      get() {
        return this.repeat_alarm
      },
      set(val) {
        this.repeat_alarm = val
        this.$store.commit('notifications/set_alarm_repeat', val)
      }
    },
    defaultSound: {
      get() {
        return this.selectedAudio
      },
      set(val) {
        this.selectedAudio = val
        this.$store.commit('notifications/set_alarm_audio', val)
      }
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.dialog = val
        if (val) {
          this.resetValidation()
          this.isNotificationGranted = Notification.permission === 'granted'
          this.currentPlaying = null
        }
      },
      immediate: true
    },
    dialog: {
      handler: function (val) {
        this.$emit('input', val)
        if (!val) {
          playAlert.player.pause()
        }
      },
      immediate: true
    }
  },
  methods: {
    save() {
      this.$emit(this.isEdit ? 'update' : 'save', this.mapping)
    },
    close() {
      this.dialog = false
    },
    isPlaying(sound) {
      return this.currentPlaying === sound.name
    },
    togglePlay(sound, index) {
      const vm = this
      if (this.isPlaying(sound)) {
        playAlert.player.pause()
        this.currentPlaying = null
        return
      }
      this.$nextTick(() => {
        playAlert(sound.name)
        this.currentPlaying = sound.name
        playAlert.player.on('ended', function () {
          vm.soundList[index].play = false
          vm.currentPlaying = null
        })
      })
    },
    askForPushNotification() {
      this.close()
      this.$event.$emit('ask-notification-permission')
    }
  }
}
</script>

<style lang="scss">
.kirby-datetime-picker {
  .datepicker {
    border: 1px solid $blue;
    margin-top: 1px;
  }
}
.radio-primary--text {
  label {
    font-weight: 500;
    font-size: 15px;
  }
}
</style>
