<template>
  <v-card flat v-bind="$attrs">
    <v-card-text>
      <v-row dense class="mb-2" align="center" justify="end">
        <v-btn
          class="text-none px-5"
          depressed
          color="primary"
          @click="action_add_edit_dialog(true, null)"
        >
          <v-icon left>mdi-plus</v-icon>
          <span>Add New</span>
        </v-btn>
        <HelpToolTip
          button
          btn-class="primary rounded-5 ml-1"
          icon="mdi-cog"
          icon-color="white"
          text="Alarm Settings"
          @click:icon="settings_dialog = true"
        >
        </HelpToolTip>
      </v-row>
      <v-row
        dense
        class="flex-wrap"
        align="start"
        v-if="!loading_items && items.length"
      >
        <v-col
          cols="12"
          md="6"
          sm="12"
          v-for="(item, index) in items"
          :key="item.id"
        >
          <EachAlarm
            v-model="items[index]"
            @delete="handleDelete(item)"
            @edit="action_add_edit_dialog(true, item)"
          ></EachAlarm>
        </v-col>
      </v-row>
      <PageLoader v-else-if="loading_items" min-height="100vh"></PageLoader>
      <Empty
        min-height="100vh"
        v-else-if="!loading_items && !items.length"
        headline="No alarms found"
      ></Empty>
    </v-card-text>
    <v-card-actions
      class="d-flex align-center justify-center"
      v-if="has_more_items"
    >
      <v-btn
        color="primary"
        class="text-none px-5"
        min-width="200"
        @click="loadMoreItems"
        :loading="loading_more_items"
        :disabled="!has_more_items || loading_more_items"
      >
        Load more
      </v-btn>
    </v-card-actions>
    <AlarmDialog
      :item="activeItem"
      v-model="add_edit_dialog"
      @save="handleSave"
      @update="handleUpdate"
    ></AlarmDialog>
    <AlarmSettingDialog v-model="settings_dialog"></AlarmSettingDialog>
  </v-card>
</template>

<script>
import { fetch_services } from '@/services/fetch/fetch_services'
import AlarmDialog from './components/AlarmDialog.vue'
import EachAlarm from './components/EachAlarm.vue'
import AlarmSettingDialog from './components/AlarmSettingDialog.vue'
export default {
  components: { EachAlarm, AlarmDialog, AlarmSettingDialog },
  name: 'Alarms',
  mixins: [fetch_services],
  data: () => ({
    settings_dialog: false
  }),
  created() {
    this.loadItems(`api/alarms`, true, null, null)
    this.$event.$on('alarm-deleted', (data) => {
      // console.log(data)
      this.removeItem(data.id, null)
    })
    this.$event.$on('alarm-updated', (data) => {
      this.replaceItem(data, 'Alarm updated', null)
    })
  },
  methods: {
    handleSave(payload) {
      this.addItem(`api/alarms`, payload, 'Alarm created', () => {
        this.action_add_edit_dialog(false, null)
      })
    },
    handleUpdate(payload) {
      this.updateItem(
        `api/alarms/${this.activeItem.id}`,
        payload,
        'Alarm updated',
        () => {
          this.action_add_edit_dialog(false, null)
        }
      )
    },
    handleDelete(item) {
      this.appConfirmation('Delete this alarm?', () => {
        this.deleteItem(`api/alarms/${item.id}`, 'Alarm deleted.', null, null)
      })
    }
  }
}
</script>

<style></style>
